import React from 'react';
import { Row } from './row';
import { Cell } from './cell';
import { FirstLevelHeader } from './first-level-header';
import { SecondLevelHeader } from './second-level-header';
import { PlannerData } from '../planner-data.interface';
import { floatFormat } from '../utils';

export interface FreezedPaneProps {
  resourceGroups: PlannerData['resourceGroups'];
  totals: PlannerData['totals'];
}

/**
 * Sub-component for the freezed section of the planner component.
 */
function FreezedPaneComponent({ resourceGroups, totals }: FreezedPaneProps) {
  return (
    <div className="freezed-pane shadow">
      <Row key="months-spacer" />
      <Row key="column-headers">
        <div className="ms-auto d-flex fw-bold">
          <Cell />
          <Cell><p className="m-auto">UF</p></Cell>
          <Cell><p className="m-auto">Rate</p></Cell>
          <Cell><p className="m-auto">HH</p></Cell>
        </div>
      </Row>
      {resourceGroups.map((resourceGroup) => (
        <React.Fragment key={`rg${resourceGroup.id}`}>
          <Row key={`rg${resourceGroup.id}`}>
            <FirstLevelHeader resourceGroup={resourceGroup} />
          </Row>
          {resourceGroup.resources.map((resource) => (
            <Row key={`rg${resourceGroup.id}-ro${resource.roleId}-r${resource.id}`}>
              <SecondLevelHeader resource={resource} />
            </Row>
          ))}
        </React.Fragment>
      ))}
      <Row key="totals">
        <div className="first-level-header ps-3 my-auto fw-bold">
          <p className="m-auto">Total</p>
        </div>
        <div className="ms-auto d-flex border-start">
          <Cell><p className="m-auto">{floatFormat(totals.clf)}</p></Cell>
          <Cell><p className="m-auto">{floatFormat(totals.rate)}</p></Cell>
          <Cell><p className="m-auto">{floatFormat(totals.plannedHours)}</p></Cell>
        </div>
      </Row>
    </div>
  );
}

export const FreezedPane = React.memo(FreezedPaneComponent);
