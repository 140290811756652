import React from 'react';
import { ResourceInGroup } from '../planner-data.interface';
import { Cell } from './cell';

export interface SecondLevelHeaderProps {
  resource: ResourceInGroup;
}

function SecondLevelHeaderComponent({ resource }: SecondLevelHeaderProps) {
  return (
    <>
      <div className="second-level-header ps-5 my-auto" title={resource.name}>
        {resource.url ? (
          <a href={resource.url}>{resource.name}</a>
        ) : (
          <span>{resource.name}</span>
        )}
      </div>
      <div className="ms-auto d-flex border-start">
        <Cell><p className="m-auto">{resource.clf}</p></Cell>
        <Cell><p className="m-auto">{resource.rate}</p></Cell>
        <Cell><p className="m-auto">{resource.plannedHours}</p></Cell>
      </div>
    </>
  );
}

export const SecondLevelHeader = React.memo(SecondLevelHeaderComponent);
