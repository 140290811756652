import React, { useEffect, useState } from 'react';
import {
  getNextWeek, getPreviousDay, getPreviousWeek
} from '../../utils/date-utils';
import { PlannerData } from '../planner/planner-data.interface';
import { ResourcesTable } from './components/resources-table';
import { WeekSelector } from './components/week-selector';
import './execution-report.scss';
import { ReportingResource } from './types';

const usersEndpoint = '/api/v1/planning/?start_date={{startDate}}&end_date={{endDate}}&group_by=USER_THEN_PROJECT_STAGE&selling_status=all&time_resolution=DAILY&value_to_display=PLANNED_HOURS';
const stagesEndpoint = '/api/v1/planning/?start_date={{startDate}}&end_date={{endDate}}&group_by=PROJECT_STAGE_THEN_USER&selling_status=all&time_resolution=DAILY&value_to_display=PLANNED_HOURS';

/**
 * Converts a date to the format used by the api andpoints (dd/mm/yyyy).
 */
function dateToAPIStr(date: Date) {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

/**
 * Replaces the startDate and endDate in a given endpoint.
 */
function getFilteredUrl(endpoint: string, startDate: Date, endDate: Date) {
  return endpoint.replace('{{startDate}}', dateToAPIStr(startDate)).replace('{{endDate}}', dateToAPIStr(endDate));
}

/**
 * Sorts the results by sub-execution percentage.
 * Lower reported percentage are listed first.
 */
function rankByExecution(data: PlannerData): ReportingResource[] {
  const resources = data.resourceGroups
    .filter((resource) => resource.isBillable)
    .map((resource) => ({
      name: resource.name,
      imageUrl: resource.imageUrl,
      url: resource.url,
      reportedHours: resource.reportedHours,
      plannedHours: resource.plannedHours,
      reportRate: resource.plannedHours ? resource.reportedHours / resource.plannedHours : 1,
    }));
  resources.sort((a, b) => {
    // Compare by rate in ascending order
    if (a.reportRate !== b.reportRate) {
      return a.reportRate - b.reportRate;
    }

    // If rates are equal, compare by plannedHours in descending order
    return b.plannedHours - a.plannedHours;
  });
  return resources;
}

/**
 * Renders two reports in cloumns: weekly execution by user and weekly execution by project stage.
 */
export function ExecutionReport() {
  const now = new Date();
  const [startDate, setStartDate] = useState(getPreviousWeek(now));
  const [endDate, setEndDate] = useState(getPreviousDay(getNextWeek(startDate)));
  const [usersReportData, setUsersReportData] = useState<ReportingResource[] | undefined>();
  const [stagesReportData, setStagesReportData] = useState<ReportingResource[] | undefined>();

  // Get data from the API...
  useEffect(() => {
    fetch(getFilteredUrl(usersEndpoint, startDate, endDate))
      .then((data) => data.json())
      .then((data: PlannerData) => {
        setUsersReportData(rankByExecution(data));
        // ...but, we do this sequentially to avoid overloading the backend server
        return fetch(getFilteredUrl(stagesEndpoint, startDate, endDate));
      })
      .then((data) => data.json())
      .then((data: PlannerData) => {
        setStagesReportData(rankByExecution(data));
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e));
  }, [startDate, endDate]);

  // Prev and Next buttons handlers
  const goToPreviousWeek = () => {
    const newWeek = getPreviousWeek(startDate);
    setUsersReportData(undefined);
    setStagesReportData(undefined);
    setStartDate(newWeek);
    setEndDate(getPreviousDay(getNextWeek(newWeek)));
  };
  const goToNextWeek = () => {
    const newWeek = getNextWeek(startDate);
    setUsersReportData(undefined);
    setStagesReportData(undefined);
    setStartDate(newWeek);
    setEndDate(getPreviousDay(getNextWeek(newWeek)));
  };

  return (
    <div className="row">
      <div className="col-12">
        Reporting week:
        <WeekSelector
          startDate={startDate}
          endDate={endDate}
          goToPreviousWeek={goToPreviousWeek}
          goToNextWeek={goToNextWeek}
        />
      </div>
      <div className="col-12 col-md-6">
        <ResourcesTable title="Execution by user" resources={usersReportData} />
      </div>
      <div className="col-12 col-md-6">
        <ResourcesTable title="Execution by project stage" resources={stagesReportData} />
      </div>
    </div>
  );
}
