$('#id_quotation').on('change', (event) => {
  const quotationID = $(event.currentTarget).val();
  const url = `/organizations/quotations/${quotationID}/summary/`;
  $.ajax({
    url,
    method: 'GET',
    success: (rawData) => {
      $('#quotation-summary').html(rawData);
    },
    error: () => {
      $('#quotation-summary').html('Ha ocurrido un error');
    }
  });
}).parent().parent()
  .append('<div id="quotation-summary"></div>');
