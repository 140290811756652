import $ from 'jquery';

// Importing Bootstrap forces the load of the type definition for the window.bootstrap global.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Bootstrap from 'bootstrap';

// Styles
import '../scss/main.scss';

// Vendors
import './vendors/choices';

// Behaviors
import './behaviors/forms';
import './behaviors/input-rut';
import './behaviors/input-time-picker';
import './behaviors/regions';
import './behaviors/nav-tabs';
import './behaviors/navbar';
import './behaviors/status';
import './behaviors/setup-value-from-quotation';
import './behaviors/stage-setup-tasks';
import './behaviors/setup-info-from-quotation';
import './behaviors/project-contact';
import './behaviors/sale-goals';
import './behaviors/date-validation';
import './behaviors/activetable';
import './behaviors/commercial-report';
import './behaviors/gantt';
import './behaviors/quotation-table-ordering';
import './behaviors/quotations-search';

// Utils
import { App } from './utils/app';

// Load React components that can be auto-loaded on DOMContentLoaded
import { ComponentLoader } from './component-loader';
import { Planner } from './components/planner';
import { SeriesChart } from './components/SeriesChart';
import { ExecutionReport } from './components/execution-report';

// Use jquery from webpack instead of from CDN
declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}
window.jQuery = $;
window.$ = $;
// Improvement: import in all files that use it, and remove jquery from webpack.common.js

ComponentLoader.registerComponent('#planner-root', Planner);
ComponentLoader.registerComponent('.project-stage-series-chart', SeriesChart);
ComponentLoader.registerComponent('.execution-report-container', ExecutionReport);

/**
 * This runs on 'DOMContentLoaded', that means it waits for every javascript to be parsed and
 * executed and waits for stylesheets and defered external scripts. This may take a long time
 * if an external resource loads too slow (should we change this to <script defer src="...">?)
 */
window.addEventListener('DOMContentLoaded', () => {
  // Load the registered react components:
  ComponentLoader.start();

  const alerts = document.querySelectorAll('.alert');
  alerts.forEach((alert) => {
    App.Utils.highlight(alert);
  });

  setTimeout(() => {
    const mainAlerts = document.querySelectorAll('.main-alert .alert');
    mainAlerts.forEach((alert) => {
      window.bootstrap.Alert.getInstance(alert)?.close();
    });
  }, 10000);

  $(() => {
    $('[data-bs-toggle="popover"]').popover(); // Popovers
  });
});
