import React from 'react';
import { ResourceGroup } from '../planner-data.interface';
import { Cell } from './cell';

export interface FirstLevelHeaderProps {
  resourceGroup: ResourceGroup;
}

function FirstLevelHeaderComponent({
  resourceGroup
}: FirstLevelHeaderProps) {
  return (
    <>
      <div className="first-level-header ps-3 my-auto fw-bold">
        <a href={resourceGroup.url} title={resourceGroup.pm ? `PM: ${resourceGroup.pm}` : resourceGroup.name}>
          {resourceGroup.name}
        </a>
      </div>
      <div className="ms-auto d-flex">
        <Cell>
          {resourceGroup.imageUrl && (
            <img
              src={resourceGroup.imageUrl}
              alt={resourceGroup.name}
              className="h-100 d-block ms-auto p-1"
            />
          )}
        </Cell>
        <Cell><p className="m-auto">{resourceGroup.clf}</p></Cell>
        <Cell />
        <Cell />
      </div>
    </>
  );
}

export const FirstLevelHeader = React.memo(FirstLevelHeaderComponent);
